import React from 'react'
import bg3 from './bg3.jpg';
import './section3.css';

export default function Section3() {
  return (
    <div className="one-section one-section-3" style={{backgroundImage: `url(${bg3})`}}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center mb-4">
            <h1 className='fw600'> Have a Look at the <br /><span className="fw900 text-sea-green">Unmatchable Features</span></h1>
          </div>
          <div className="col-md-10 mx-auto section-box1">  
            <div className="section2-box-inn">
              <div className="row">
                <div className="col-lg-6 col-md-6 pt-2">
                  <ul className="checklist1">
                    <li><b>Manage</b> thousands of <b>leads</b> in an organized way</li>
                    <li>Create <b>groups, lists, &amp; segments</b> as per your need</li>
                    <li><b>Suppress</b> email, domain, or IP</li>
                    <li>Perform <b>bulk actions</b> on contacts including set as <b>soft bounce, hard bounce</b>, not bounced, mark confirmed, mark unconfirmed, set as <b>active or inactive</b>, or delete a contact.</li>
                    <li>Upload a <b>CSV file</b> or select a file from the <b>server</b>.</li>
                    <li>Choose the <b>format</b> of the <b>email</b> you want your users to receive i.e., <b>HTML or TEXT.</b></li>
                    <li>Perform <b>bulk updates</b> to the selected file as per <b>requirements</b>.</li>
                    <li><b>Bulk update</b> your contacts to <b>unsubscribe, not subscribed</b>, hard bounced, soft bounced, change the format from <b>HTML to TEXT</b>, or vice versa, etc.</li>
                    <li>Send <b>HTML and TEXT</b> body of your emails.</li>
                    <li>Send a <b>preview of the email</b> to the <b>desired email</b> before you send it to the <b>audience</b>.</li>
                    <li>Run <b>Split Test</b> to find the <b>best variant</b> of your email</li>
                    <li>Use <b>Dynamic Content Tags</b> to send <b>personalized content</b> based on specific criteria.</li>
                    <li><b>Choose a trigger</b> and let it happen <b>automatically</b> on an action to show the direction to the users.</li>
                    <li>Send your post <b>instantly or schedule</b> it to use it to <b>send it later</b></li>
                    <li>Add <b>Webhooks</b> to send <b>status</b> notifications to your <b>endpoints</b> as soon as an event occurs matching your <b>criteria</b>.</li>
                    <li><b>Customize landing pages</b> to make them all yours according to your <b>brand and product</b>.</li>
                    <li>Add <b>sender information</b> from the contact list or add <b>custom information</b> that you want to send</li>
                    <li>Add your <b>sending domains</b> that appear in the <b>message preheader</b></li>
                    <li>Add <b>web forms</b> and embed <b>HTML code</b> to your website to generate more leads</li>
                    <li>Add <b>Pixels</b> to track the activity of your <b>visitor</b> on your <b>website</b></li>
                    <li>View the <b>analytics</b> and <b>detailed logs</b> of the emails relaying through your account</li>
                  </ul>
                </div>
                <div className="col-lg-6 col-md-6 pt-2">
                  <ul className="checklist1">
                    <li>Create your <b>lists or import</b> a file</li>
                    <li>Create <b>custom fields</b> to add more and more information about the contacts</li>
                    <li><b>Smart segmentation</b> based on <b>contact lists</b> and based on <b>statistics</b></li>
                    <li><b>Import your contacts</b> from other platforms such as Gmail, etc.</li>
                    <li>Use <b>rocket import</b> to increase <b>uploading speed</b></li>
                    <li>Select a third party to <b>import your contacts,</b> such as Active Campaign, Benchmark, Get Response, MailChimp, etc.</li>
                    <li>Select the <b>file source</b>, and upload the file</li>
                    <li><b>Design broadcasts</b> using a world-class <b>HTML Editor, or Drag & Drop Builder</b></li>
                    <li>Feel free to <b>fetch</b> your content from the <b>URL</b></li>
                    <li>Send a series of <b>systematic emails</b> through a <b>drip campaign</b> to convert your <b>leads</b>.</li>
                    <li>Use <b>Spintags</b> to add variations in your <b>campaigns</b> to make it different for every <b>contact in the list</b></li>
                    <li>Send <b>Event-Based Triggers</b> to manipulate your <b>broadcasts</b> and <b>drip campaigns</b>.</li>
                    <li><b>Design and schedule</b> unlimited <b>broadcasts</b> as you like</li>
                    <li>Resume or <b>pause your broadcasts</b> whenever you need</li>
                    <li>Choose from the dozens of <b>pre-made landing pages</b> to direct your audience to a <b>dedicated page</b>.</li>
                    <li>Set the <b>hourly speed</b> of your <b>email campaigns</b></li>
                    <li><b>Track opening</b> and <b>clicks</b> of your emails</li>
                    <li>Overwrite <b>From Name and Subject Line</b>, if you want to do that</li>
                    <li>Use <b>Dedicated Pools</b> and <b>Dedicated IPs</b></li>
                    <li>Generate <b>API key</b> to connect <b>Mumara One</b> with any <b>third-party application</b></li>
                    <li>View <b>real-time statics</b> to examine the performance of your <b>Broadcasts and Triggers</b></li>
                    <li>View <b>received, delayed, sent delivered, failed,</b> and <b>complaints</b> emails</li>
                    <li>Get a detailed <b>summary</b> of received, bounced, and queued emails to <b>analyze</b> the performance</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
